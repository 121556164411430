import React, { useRef, useEffect } from "react"
import { PageRenderer, graphql, navigate } from "gatsby"
import PropTypes from "prop-types"
import Seo from "../components/seo"
import he from "he"

import RenderBlock from "../utils/render-block"
import Link from "../utils/link"
import Fade from "react-reveal/Fade"

import WingspanLogo from "../assets/images/Vectors/WingspanFullMark.svg"
import CloseIcon from "../assets/images/Vectors/times.svg"
import RightArrow from "../assets/images/Vectors/np_arrow-right_888647_FFFFFF-1.svg"
import LeftArrow from "../assets/images/Vectors/np_arrow-right_888647_FFFFFF.svg"
import StandAloneLogo from "../assets/images/Vectors/Shape.svg"
import PlayButton from "../assets/images/Vectors/play.svg"
import "./CaseStudyPopupTemplate.scss"

const VideoComponent = ({ content }) => {
  const { videoUrl, videoThumbnail } = content

  const playBtn = useRef(null)
  const videoContainer = useRef(null)

  useEffect(() => {}, [playBtn])

  const handlePause = () => {
    playBtn.current.classList.remove("hide")
    playBtn.current.classList.add("show")
  }

  const handlePlay = () => {
    playBtn.current.classList.add("hide")
  }

  const handlePlayback = () => {
    videoContainer.current.play()
  }

  return (
    <Fade>
      <div className="videoWrapper">
        { videoUrl &&
          <video
            onPause={handlePause}
            onPlay={handlePlay}
            loop
            poster={videoThumbnail?.localFile?.publicURL}
            ref={videoContainer}
            className="videoContainerCaseStudy--desktop"
            controls
          >
            <source src={videoUrl} type="video/mp4" />
          </video>
        }
        { videoUrl &&
          <img
            loading="lazy"
            onClick={handlePlayback}
            className="playButtonPopup"
            src={PlayButton}
            alt="play-button"
            ref={playBtn}
          />
        }
        { !videoUrl && videoThumbnail &&
          <img 
            className='videoContainerCaseStudy--desktop no-video'
            src={videoThumbnail?.localFile?.publicURL} 
            alt={'Wingspan Capital'} 
          />
        }
      </div>
    </Fade>
  )
}

const CaseStudyPopupTemplate = ({ data }) => {
  const {
    heading,
    subHeading,
    grossRealisationHeading,
    grossRealisationValue,
    apartmentsBuiltHeading,
    apartmentsBuilt,
    constructionSizeHeading,
    constructionSize,
    completionDateHeading,
    completionDate,
    description,
    nextPopupLinkAndTitle,
  } = data.wpCaseStudy.acfCaseStudies

  const overlay = useRef(null)

  const navigateBack = (e) => {
    e.preventDefault()
    const position = parseInt(sessionStorage.getItem("positionInitial"))

    sessionStorage.removeItem("positionInitial")
    if (position) {
      sessionStorage.setItem("position", position)
    }
    navigate(sessionStorage.getItem("previousPagePath"))
  }

  return (
    <div>
      <PageRenderer
        key={"/case-studies"}
        location={{ pathname: "/case-studies" }}
      />

      <div
        ref={overlay}
        onLoad={() => {
          overlay.current.focus()
        }}
        className="overlay-page"
        tabIndex="0"
      >
        <div className="caseStudyPopup">
          <div className="caseStudyPopup__header caseStudyPopup__container">
            <Fade>
              <Link to="/" title="Wingspan Capital" className="header__logo">
                <img src={WingspanLogo} />
              </Link>
            </Fade>
            <Fade bottom>
              <div className="content__closeTrigger">
                <button onClick={navigateBack}>
                  <img
                    onClick={navigateBack}
                    className="content__close"
                    src={CloseIcon}
                    alt="close button"
                  />
                </button>
              </div>
            </Fade>
          </div>
          <div className="caseStudyPopup__content caseStudyPopup__container">
            <Fade>
              <section className="caseStudyPopup__intro">
                <article className="caseStudyPopup__category">
                  <h2
                    className="caseStudyPopup__category__title"
                    dangerouslySetInnerHTML={{ __html: subHeading }}
                  />
                </article>
                <article className="caseStudyPopup__projectHeading">
                  <h2
                    className="caseStudyPopup__projectHeading__project"
                    dangerouslySetInnerHTML={{ __html: heading }}
                  />
                </article>
              </section>
            </Fade>
            <section className="caseStudyPopup__video">
              <VideoComponent content={data.wpCaseStudy.acfCaseStudies} />
            </section>

            <section className="caseStudyPopupSlider">
              <Fade>
                <article className="caseStudyPopupSlider__sliderControl">
                  <img
                    className="caseStudyPopupSlider__sliderRight"
                    src={RightArrow}
                    alt="right-arrow"
                  />
                  <img
                    className="caseStudyPopupSlider__sliderLeft"
                    src={LeftArrow}
                    alt="left-arrow"
                  />
                </article>
              </Fade>
              <article className="caseStudyPopupSlider__sliderData">
                <article className="caseStudyPopupSlider__sliderInfo">
                  <Fade>
                    <div className="caseStudyPopupSlider__wrapper">
                      <div className="caseStudyPopupSlider__content">
                        <div className="caseStudyPopupSlider__contents">
                          <h2 className="caseStudyPopupSlider__sliderHeading">
                            {`${grossRealisationValue}m`}
                          </h2>
                          <p className="caseStudyPopupSlider__sliderPara">
                            {grossRealisationHeading}
                          </p>
                        </div>
                        <div className="caseStudyPopupSlider__contents">
                          <h2 className="caseStudyPopupSlider__sliderHeading">
                            {`${apartmentsBuilt}`}
                          </h2>
                          <p className="caseStudyPopupSlider__sliderPara">
                            {apartmentsBuiltHeading}
                          </p>
                        </div>
                        <div className="caseStudyPopupSlider__contents">
                          <h2 className="caseStudyPopupSlider__sliderHeading">
                            {`${constructionSize}`}
                          </h2>
                          <p className="caseStudyPopupSlider__sliderPara">
                            {constructionSizeHeading}
                          </p>
                        </div>
                        <div className="caseStudyPopupSlider__contents">
                          <h2 className="caseStudyPopupSlider__sliderHeading">
                            {`${completionDate}`}
                          </h2>
                          <p className="caseStudyPopupSlider__sliderPara">
                            {completionDateHeading}
                          </p>
                        </div>
                      </div>
                    </div>
                  </Fade>
                </article>
              </article>
            </section>

            <div className="caseStudyPopup__wrapper">
              <aside className="caseStudyPopup__details ">
                <Fade>
                  <p
                    className="caseStudyPopup__info"
                    dangerouslySetInnerHTML={{ __html: description }}
                  />
                </Fade>
                <>
                  {data.wpCaseStudy && <Seo title={he.decode(data.wpCaseStudy.seo.title)} description={data.wpCaseStudy.seo.metaDesc} />}
                  {data.wpCaseStudy &&
                    data.wpCaseStudy.acf.contentBlocks &&
                    data.wpCaseStudy.acf.contentBlocks.map((el, i) =>
                      RenderBlock(el.fieldGroupName, el, i, "case_study")
                    )}
                </>
                <Fade>
                  <section className="caseStudyPopup__navigation">
                    <div className="caseStudyPopup__buttonContainer">
                      <Link
                        to={"/case-studies" + nextPopupLinkAndTitle.url}
                        className="caseStudyPopup__nextCase"
                      >
                        {nextPopupLinkAndTitle.title}
                      </Link>
                      <Link to={"/case-studies" + nextPopupLinkAndTitle.url}>
                        <img
                          className="caseStudyPopup__arrowImage"
                          loading="lazy"
                          src={RightArrow}
                          alt="arrow"
                        />
                      </Link>
                    </div>

                    <button
                      onClick={navigateBack}
                      className="caseStudyPopup__closeTrigger"
                    >
                      Close
                    </button>
                  </section>
                </Fade>
              </aside>
            </div>
            <Fade>
              <footer className="caseStudyPopup__footer">
                <div className="caseStudyPopup__footerWrapper">
                  <Link to="/">
                    <img
                      className="caseStudyPopup__standaloneLogo"
                      src={StandAloneLogo}
                      alt="standaloneLogo"
                    />
                  </Link>
                </div>
              </footer>
            </Fade>
          </div>
        </div>
      </div>
    </div>
  )
}

export const pageQuery = graphql`
  query($id: String) {
    wpCaseStudy(id: { eq: $id }) {
      slug
      title
      seo {
        title
        metaDesc
        opengraphTitle
        opengraphDescription
        opengraphImage {
          altText
          localFile {
            childImageSharp {
              original {
                src
                width
                height
              }
            }
          }
        }
      }
      acf {
        contentBlocks {
          ... on WpCase_study_Acf_ContentBlocks_CaseStudyPopupAsideImage {
            fieldGroupName
            rightAlignedImage {
              localFile {
                publicURL
              }
            }
          }
          ... on WpCase_study_Acf_ContentBlocks_CaseStudiesPopupList {
            fieldGroupName
            caseStudyPopupListOne {
              caseStudyPopupItem1
            }
            caseStudyPopupListTwo {
              caseStudyPopupItem2
            }
          }
          ... on WpCase_study_Acf_ContentBlocks_CaseStudyPopupAdditionalDescription {
            fieldGroupName
            information {
              content
            }
          }
        }
      }
      acfCaseStudies {
        subHeading
        heading
        apartmentsBuiltHeading
        apartmentsBuilt
        completionDateHeading
        completionDate
        constructionSizeHeading
        constructionSize
        description
        fieldGroupName
        firstRoundHeading
        firstRoundRetailSpacesBuilt
        grossRealisationHeading
        grossRealisationValue
        videoUrl
        videoThumbnail {
          localFile {
            publicURL
          }
        }
        nextPopupLinkAndTitle {
          title
          url
        }
      }
      slug
      title
    }
  }
`

VideoComponent.propTypes = {
  videoThumbnail: PropTypes.object,
  videoData: PropTypes.object,
  content: PropTypes.object,
}

CaseStudyPopupTemplate.propTypes = {
  data: PropTypes.object,
  grossRealisationValue: PropTypes.string,
  apartmentsBuilt: PropTypes.number,
  completionDate: PropTypes.string,
  constructionSize: PropTypes.number,
  description: PropTypes.string,
  nextPopupLinkAndTitle: PropTypes.object,
}

export default CaseStudyPopupTemplate
